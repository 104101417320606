import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import Spinner from "./components/UI/Spinner";
import store from "./redux/";
import Config from "./configure";
import "./index.scss";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./index.scss";
import "./assets/fonts/feather/css/feather.css";
import "flag-icon-css/sass/flag-icon.scss";
import "@splidejs/splide/dist/css/themes/splide-skyblue.min.css";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

import swal from "sweetalert";
window.swal = swal;

const App = lazy(() => import("./App"));

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()]
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

document.title = "Aurum Virtual Mirror Management App";

const app = (
  <ErrorBoundary>
    <Provider store={store}>
      <BrowserRouter basename={Config.basename}>
        <Suspense fallback={<Spinner />}>
          <App />
        </Suspense>
      </BrowserRouter>
    </Provider>
  </ErrorBoundary>
);

ReactDOM.render(app, document.getElementById("root"));
