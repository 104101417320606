import React from "react";

const Spinner = () => (
  <div className="loader-bg">
    <div className="loader-track">
      <div className="loader-fill" />
    </div>
  </div>
);

export default Spinner;
