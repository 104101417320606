const initialState = {
  title: "",
  paths: []
};

// paths: [{
//   'name' : "Kolorowanie",
//   'path' : "/coloring"
// }]

const Breadcrumb = (state = initialState, action) => {
  switch (action.type) {
    case "BREAD_SET":
      return {
        ...state,
        title: action.title,
        paths: action.paths
      };
    case "BREAD_CLEAR":
      return initialState;
    default:
      return state;
  }
};

export default Breadcrumb;
